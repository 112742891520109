import React, { ReactNode } from "react";

import { CollectionGrantFragment } from "../../mutations/collections.generated.js";
import { MenuItemWithTooltip } from "../app/HexOptionsMenu.js";
import { CollectionSharingSummary } from "../home/collections-tab/shared/CollectionSharingSummary.js";
import { CollectionAvatar } from "../user/CollectionAvatar.js";

export interface CollectionMenuItemProps {
  name: string;
  emoji?: ReactNode;
  collectionGrants?: readonly CollectionGrantFragment[];
  isActive?: boolean;
  isDisabled?: boolean;
  handleClick?: React.MouseEventHandler<HTMLElement>;
  disabledMessage: string;
}

export const CollectionMenuItem: React.ComponentType<CollectionMenuItemProps> =
  React.memo(function CollectionMenuItem({
    collectionGrants,
    disabledMessage,
    emoji,
    handleClick,
    isActive = false,
    isDisabled = false,
    name,
  }: CollectionMenuItemProps) {
    return (
      <MenuItemWithTooltip
        active={isActive}
        htmlTitle={name}
        icon={<CollectionAvatar emoji={emoji} />}
        isMenuDisabled={isDisabled}
        isTooltipDisabled={!isDisabled}
        labelElement={
          <CollectionSharingSummary
            collectionGrants={collectionGrants}
            disableTooltip={isDisabled}
            minimal={true}
          />
        }
        text={name}
        tooltipContent={disabledMessage}
        tooltipPlacement="left"
        onClick={handleClick}
      />
    );
  });
